import React from 'react';
import Footer from '../components/SideBar/Footer'
import config from '../../config';

export default function PageFooter() {
  return (
    <div id="footer">
        <Footer socialLinks={config.socialLinks} />

    </div>
  );
}
