import React from "react"
import { SiJavascript, SiHtml5, SiCss3, SiNodeDotJs, SiPostgresql, SiReact, SiMongodb, SiBootstrap, SiWordpress, SiPython, SiDjango, SiGithub, SiAdobephotoshop, SiAdobelightroomcc} from "react-icons/si";

const Languages = () => {

    return (
        <div id="languages-icons">
        <ul id="languages-ul">
            <li><SiJavascript title="JavaScript"></SiJavascript></li>
            <li><SiHtml5 title="HTML"></SiHtml5></li>
            <li><SiCss3 title="CSS"></SiCss3></li>
            <li><SiNodeDotJs title="Node.js"></SiNodeDotJs></li>
            <li><SiPostgresql title="PostgreSQL"></SiPostgresql></li>
            <li><SiReact title="React"></SiReact></li>
            <li><SiMongodb title="MongoDB"></SiMongodb></li>
            <li><SiBootstrap title="Bootstrap"></SiBootstrap></li>
            <li><SiWordpress title="WordPress"></SiWordpress></li>
            <li><SiPython title="Python"></SiPython></li>
            <li><SiDjango title="Django"></SiDjango></li>
            <li><SiGithub title="GitHub"></SiGithub></li>
            <li><SiAdobephotoshop title="Adobe Photoshop"></SiAdobephotoshop></li>
            <li><SiAdobelightroomcc title="Adobe Lightroom"></SiAdobelightroomcc></li>
        </ul>

        </div>
    )
}

export default Languages;