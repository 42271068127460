import React from 'react';

import Layout from '../components/Layout';
import PageFooter from '../components/PageFooter';
import SideBar from '../components/SideBar';
import Languages from '../components/Languages';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import Pic from '../components/Pic';



const sections = [
  { id: 'top', name: 'About', icon: 'fa-home' },
  { id: 'portfolio', name: 'Projects', icon: 'fa-th' },
  { id: 'languages', name: 'Languages', icon: 'fa-globe' },
  { id: 'contact', name: 'Contact Me', icon: 'fa-envelope' },
  // { id: 'backToTop', name: 'Back To Top', icon: 'fa-arrow-up' },
];

const IndexPage = () => (
  <Layout>
    <SideBar sections={sections} />

    <div id="main">

      <section id="top" className="one dark cover">
        <div className="container">
          <header>
            <style>
            </style>
            
            <h2 id="hi">
              Hi! I'm 

                <div class="flyinTxtCont">
                <div class="flyIn lineOne"><strong> Marisela Espinoza</strong></div>
              </div>
            </h2>

            <Pic />

            <h3>About Me</h3>

            <p>I am a kinesiologist who has found her passion for <span class="highlight">software engineering.</span> 
            One of the things that I enjoy the most is making <span class="highlight"></span>great
            looking websites utilizing HTML, CSS & JavaScript. What inspires me
            to do great work is seeing the <span class="highlight">client-focused final product.</span> I enjoy
            breaking down big problems into <span class="highlight">smaller and more manageable ones.</span> 
            One of the things I love the most is expressing my creativity through code or photography.</p>

            
          </header>
        </div>
      </section>

      <section id="portfolio" className="two">
        <div className="container">

          <h2>Projects</h2>
          <br></br>
          <Projects />
        </div>
      </section>

      <section id="languages" className="three">
        <div className="container">
          <header>
            <h2>Languages</h2>
          </header>

          <Languages />
          <br></br>
          <h3>& more!</h3>
        </div>
      </section>

      <section id="contact" className="four">
        <div className="container">
          <header>
              <h2>Contact Me</h2>
          </header>

          <div className="typing-all">
            <div className="typing-demo">
              Send me a message below or at m.espinoza16@hotmail.com
            </div>
          </div>
          {/* <p>Send me a message below or at m.espinoza16@hotmail.com </p> */}
          <Contact />
        </div>
      </section>
    </div>

    {/* <section id="resume">

    </section> */}

    <PageFooter />
  </Layout>
);

export default IndexPage;
