import React from 'react';
import { FaGithubSquare } from "react-icons/fa";
import { RiSlideshow4Fill } from "react-icons/ri";
import '../assets/sass/project.scss';



const Projects = () => {
    return (
        <section>

            <div className="proj-container">
                <div className="left-one">
                </div>
                <div className="right-one">
                    <div class="content">
                        <h1>KCal</h1>
                        <p>Users can enter the food that they are consuming as well as the calories. Kcal will keep track of these foods and subtract the calories from their daily calorie goal.</p>
                        <h6>Python | Django | Postgres</h6>
                        <a href="https://k-cal.herokuapp.com/" className="project-btn" target="_blank" alt="View Live"><RiSlideshow4Fill title="View Live" className="project-icons" size={30} /></a>
                        <a href="https://github.com/mespinoza30/Kcal/tree/Marisela" className="project-btn" target="_blank"><FaGithubSquare title="GitHub" className="project-icons" size={30} /></a>
                    </div>
                </div>
            </div>

            <br></br>

            <div className="proj-container">
            <div className="right-two">
                    <div class="content">
                        <h1 class="card-title">Tail Wag!</h1>
                        <p>Users are able to enter their zip code to search for dogs or cats that are currently available for adoption near their area.</p>
                        <h6>React | CSS | Express | MongoDB | Mongoose</h6>
                        <a href="https://tail-wag.herokuapp.com/" className="project-btn" target="_blank"><RiSlideshow4Fill title="View Live" className="project-icons" size={30} /></a>
                        <a href="https://github.com/digeoesp/Tail-Wag-frontend/tree/Marisela" className="project-btn" target="_blank"><FaGithubSquare title="GitHub" className="project-icons" size={30} /></a>
                    </div>
                </div>
                <div className="left-two"></div>
            </div>

            <br></br>

            <div className="proj-container">
                <div className="left-three"></div>
                <div className="right-three">
                    <div class="content">
                        <h1>Shutter-Up!</h1>
                        <p>A website that allows photographers of all skill levels to upload and share their images.</p>
                        <h6>HTML | CSS | JavaScript | Express | Postgres | Sequelize | Cloudinary</h6>
                        <a href="https://shutter-up21.herokuapp.com/" className="project-btn" target="_blank"><RiSlideshow4Fill title="View Live" className="project-icons" size={30} /></a>
                        <a href="https://github.com/mespinoza30/Shutter-Up" className="project-btn" target="_blank"><FaGithubSquare title="GitHub" className="project-icons" size={30} /></a>
                    </div>
                </div>
            </div>

            <br></br>

            <div className="proj-container">
            <div className="right-four">
                    <div class="content">
                        <h1>Guess the RGB</h1>
                        <p>This is an interactive game that tests the player's RGB knowledge. </p>
                        <h6>HTML | CSS | JavaScript</h6>
                        <a href="https://mespinoza30.github.io/Guess-the-RGB/" className="project-btn" target="_blank"><RiSlideshow4Fill title="View Live" className="project-icons" size={30} /></a>
                        <a href="https://github.com/mespinoza30/Guess-the-RGB" className="project-btn" target="_blank"><FaGithubSquare title="GitHub" className="project-icons" size={30} /></a>
                    </div>
                </div>
                <div className="left-four"></div>
            </div>

        </section>
    );
}

export default Projects;
