import React from 'react';
import ProfilePic from '../assets/images/profilepic.png'
import '../assets/sass/pic.scss';
import { FaGithubSquare, FaLinkedinIn, FaEnvelope, FaIdCard } from "react-icons/fa";

const Pic = () => {
    return (
        <div class="main">
            <div class="pic-card">
                <img src={ProfilePic}></img>
                <div class="logos ic1">
                    <a href="https://drive.google.com/file/d/1_DrhRjg4P3pNHdIqfEeSXI3Oz4Jo626n/view?usp=sharing" target="_blank"> <i class="fab"><FaIdCard/></i> </a>
                    <a href="mailto:m.espinoza16@hotmail.com" target="_blank"> <i class="fab"><FaEnvelope/></i> </a>
                </div>

                <div class="logos ic2">
                    <a href="https://www.linkedin.com/in/marisela-espinoza/" target="_blank"> <i class="fab"><FaLinkedinIn/></i> </a>
                    <a href="https://github.com/mespinoza30" target="_blank"> <i class="fab"><FaGithubSquare/></i> </a>
                </div>

            </div>
            
        </div>
    );
}

export default Pic;
